import React from 'react';
import { Box, Checkbox, Link, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider/QuizProvider.hooks';
import { Routes } from '../../../App';
import { endTrial } from '../../../utils/common';
import { usePaymentState } from '../../../providers/PaymentProvider/PaymentProvider.hooks';
import { APP_CONSTANTS } from '../../../utils/constants';

function ChoosePlan() {
  const { setQuizData, quizData } = useQuizState();
  const { paymentPlans, selectedPaymentPlan, setSelectedPaymentPlan } = usePaymentState();
  const isMostPopular = false;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        {paymentPlans?.map((plan, index) => (
          <Box
            key={plan.name}
            sx={{
              borderRadius: '20px',
              padding: '20px',
              background: '#fff',
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
              border: isMostPopular ? '2px solid #1364FF' : undefined,
              overflow: 'hidden',
            }}
            onClick={() => setSelectedPaymentPlan(plan)}
          >
            {isMostPopular && (
              <Box
                sx={{
                  background: '#1364FF',
                  marginTop: '-20px',
                  marginLeft: '-20px',
                  marginRight: '-20px',
                  marginBottom: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '3px',
                }}
              >
                <img src={'./images/subscription/star.svg'} alt="star"></img>
                <Typography
                  sx={{
                    fontFamily: 'Poppins700',
                    fontSize: '10px',
                    color: '#fff',
                    userSelect: 'none',
                    textTransform: 'uppercase',
                    margin: '4px 0',
                  }}
                >
                  most popular PLAN
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  marginRight: '10px',
                }}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    outline:
                      plan.name === selectedPaymentPlan?.name ? undefined : '1px solid rgba(129, 163, 193, 0.50)',
                    borderRadius: '50%',
                  }}
                >
                  {plan.name === selectedPaymentPlan?.name && (
                    <img src={'./images/subscription/check_icon_round.svg'} alt="checked"></img>
                  )}
                </Box>
                <Typography
                  sx={{
                    fontFamily: 'Poppins500',
                    fontSize: '16px',
                    userSelect: 'none',
                  }}
                >
                  {plan.name_full}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins500',
                    fontSize: '14px',
                    color: '#F32A2D',
                    userSelect: 'none',
                    textDecoration: 'line-through',
                  }}
                >
                  ${'3.33'}/DAY
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins600',
                    fontSize: '20px',
                    userSelect: 'none',
                    color: '#051C38',
                    '& .day': {
                      fontFamily: 'Poppins500',
                      fontSize: '14px',
                      userSelect: 'none',
                      color: '#81A3C1',
                    },
                  }}
                >
                  ${(plan.trial_sum / 3).toFixed(2)}
                  <span className="day">/DAY</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', gap: '5px', margin: '20px 0', alignItems: 'center' }}>
        <Box
          sx={{
            marginLeft: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Checkbox
            checked={quizData.isAgreed}
            value={quizData.isAgreed}
            onClick={() => setQuizData((prev) => ({ ...prev, isAgreed: !prev.isAgreed }))}
            checkedIcon={<img src={'./images/subscription/check_icon_square.svg'} alt="checkbox"></img>}
            sx={{
              color: '#1364FF',
            }}
          ></Checkbox>
        </Box>
        <Typography
          sx={{
            color: '#051C38',
            fontFamily: 'Poppins400',
            fontSize: '12px',
            userSelect: 'none',
            marginRight: '20px',

            '& .link': {
              color: '#1364FF',
              textDecoration: 'underline',
              transition: 'all 0.3s ease',
              whiteSpace: 'nowrap',
              '&:hover': {
                opacity: 0.8,
              },
            },
          }}
        >
          I got acquainted with the{' '}
          <Link href={Routes.TERMS} className="link" target="_blank">
            terms of use
          </Link>{' '}
          and the rules of auto-renewal subscription
        </Typography>
      </Box>
      <QuizButton
        disabled={!quizData.isAgreed}
        onClick={() => {
          setQuizData((prev) => ({ ...prev, isPaymentModalOpen: true }));
        }}
        variant={ButtonType.BLUE}
        text="Continue"
      ></QuizButton>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          margin: '20px 0',
        }}
      >
        <img src={'./images/subscription/shield.svg'} alt="shield"></img>
        <Typography
          sx={{
            fontFamily: 'Poppins400',
            fontSize: '14px',
            lineHeight: 'normal',
            textAlign: 'center',
            userSelect: 'none',
            color: '#051C38',
          }}
        >
          100% money back guarantee
        </Typography>
      </Box>
      <Box
        sx={{
          background: 'rgba(129, 163, 193, 0.20)',
          borderRadius: '16px',
          padding: '15px 20px 20px',
          '& img': {
            width: '100%',
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins500',
            fontSize: '14px',
            lineHeight: 'normal',
            textAlign: 'center',
            color: '#051C38',
            userSelect: 'none',
            marginBottom: '10px',
          }}
        >
          Safe & Secure Payment
        </Typography>
        <img draggable={false} src={'./images/subscription/payment_methods.svg'} alt="payments"></img>
      </Box>
      <Typography
        sx={{
          fontFamily: 'Poppins400',
          fontSize: '12px',
          lineHeight: 'normal',
          textAlign: 'center',
          userSelect: 'none',
          color: '#81A3C1',
          marginTop: '20px',
          marginBottom: '40px',
          '& a': {
            color: '#81A3C1',
          },
        }}
      >
        Your {selectedPaymentPlan?.name} intro offer last until{' '}
        {endTrial(
          selectedPaymentPlan?.trial_length || '',
          Number(selectedPaymentPlan?.trial_length.slice(0, selectedPaymentPlan?.trial_length.length - 1)) || 0
        )}
        . Then your weekly subscription will start and you'll be charged $ {selectedPaymentPlan?.sum} every 1 week. You
        can cancel anytime in your profile or just write us a letter:{' '}
        <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>
      </Typography>
    </Box>
  );
}

export default ChoosePlan;
