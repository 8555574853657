import React from 'react';

import { Box, Link, Typography } from '@mui/material';
import { Routes } from '../../App';
import QuizButton, { ButtonType } from '../common/QuizButton';
import { APP_CONSTANTS } from '../../utils/constants';

function DownloadApp() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#CFE3F4',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '430px',
          backgroundColor: '#E6F3FE',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '0 20px',
            margin: '30px 0 50px',
          }}
        >
          <img width={292} height={194} src={'./images/congrats/thumb.webp'} alt="background"></img>

          <Typography
            sx={{
              fontFamily: 'Poppins700',
              fontSize: '32px',
              color: '#051C38',
              lineHeight: '44px',
              textAlign: 'center',
              userSelect: 'none',
              margin: '10px 0',
            }}
          >
            Congratulations!
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins400',
              fontSize: '16px',
              color: '#051C38',
              textAlign: 'center',
              lineHeight: '24px',
              userSelect: 'none',
            }}
          >
            Your payment was successful. Transform your iOS mobile device to GPS tracker (GPS tracking device) and use
            all its features.
          </Typography>
          <Box
            sx={{
              margin: '30px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: '#fff',
              borderRadius: '30px',
              padding: '30px',
              gap: '15px',
            }}
          >
            <img width={72} height={72} src={'./images/congrats/app.webp'} alt="heart"></img>

            <Typography
              sx={{
                fontFamily: 'Poppins600',
                fontSize: '20px',
                color: '#051C38',
                textAlign: 'center',
                userSelect: 'none',
                '& .blue': {
                  color: '#1364FF',
                },
              }}
            >
              Download application from AppStore & <span className="blue">start tracking your family</span>
            </Typography>
            <QuizButton
              onClick={() => {
                window.open(APP_CONSTANTS.DOWNLOAD_APP_LINK, '_blank');
              }}
              variant={ButtonType.BLUE}
              text="Download app"
            ></QuizButton>
          </Box>
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins400',
                fontSize: '12px',
                color: '#051C38',
                textAlign: 'center',
                userSelect: 'none',
                // marginTop: '30px',
              }}
            >
              All rights reserved. 2024
            </Typography>
            <Box
              sx={{
                height: '1px',
                width: '100%',
                background: 'rgba(129, 163, 193, 0.20)',
                margin: '15px 0',
              }}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-around',
                '& .link': {
                  fontFamily: 'Poppins400',
                  fontSize: '12px',
                  color: '#051C38',
                  textDecoration: 'none',
                  transition: 'all 0.3s ease',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    opacity: 0.8,
                  },
                },
              }}
            >
              <Link target="_blank" href={Routes.PRIVACY} className="link">
                Privacy Policy
              </Link>
              <Link target="_blank" href={Routes.TERMS} className="link">
                Terms of Use
              </Link>
              <Link target="_blank" href={Routes.COOKIE_POLICY} className="link">
                Cookie Policy
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DownloadApp;
