import React from 'react';
import { Box, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import ChoosePlan from './ChoosePlan';

function MainContent() {
  const reviews = [
    {
      name: 'Maggie',
      position: 'Mother of Three',
      text: (
        <span>
          Since downloading <span className="bold">FamilyTracker</span>, I've found a newfound sense of calmness in my
          day-to-day life. It's like having a safety net for my family that I can trust. Knowing my kids are just a
          shake away from alerting me if something's wrong gives me such peace of mind. Plus, seeing their little icons
          moving across my screen makes me feel connected to them, even when we're apart.
        </span>
      ),
      image: './images/subscription/features/avatar_1.webp',
    },
    {
      name: 'Dave',
      position: 'Home-to-Work-Traveler',
      text: (
        <span>
          I'm often on the road for work, and <span className="bold">FamilyTracker</span> has been a game-changer for my
          wife and me. She can see my driving routes, and I can check in on her and the kids' locations without
          constantly texting. The driving reports have also made me more aware of my speed—something she appreciates a
          lot. It's reassuring to have that extra layer of connectivity with my family.
        </span>
      ),

      image: './images/subscription/features/avatar_2.webp',
    },
    {
      name: 'Tom',
      position: 'Single Dad',
      text: (
        <span>
          Balancing work and family life as a single dad is tough, especially when it comes to keeping tabs on my son's
          after-school whereabouts. <span className="bold">FamilyTracker</span> has been invaluable. Not only can I make
          sure he's safe, but the location history feature also lets me review his day, which always gives us plenty to
          talk about over dinner. It's more than an app; it's a tool that keeps my family bonded.
        </span>
      ),

      image: './images/subscription/features/avatar_3.webp',
    },
    {
      name: 'Linda',
      position: 'College Freshman',
      text: (
        <span>
          Heading off to college was a big step for me and my parents. <span className="bold">FamilyTracker</span> has
          made the transition easier for us all. They feel better knowing I can quickly send an alert if I ever feel
          unsafe, and I like that I can share my location with them during late-night study sessions. It's the perfect
          balance of independence and safety.
        </span>
      ),

      image: './images/subscription/features/avatar_4.webp',
    },
  ];

  const features = [
    {
      title: 'Place Alert',
      subTitle:
        'Get notified instantly when family members arrive at or leave designated locations. Peace of mind has never been this simple.',
      image: './images/subscription/features/features_image_1.webp',
    },
    {
      title: 'Driving Report',
      subTitle:
        'Encourage safer driving habits with detailed insights into speed, driving events, and route efficiency. Because knowing is caring.',
      image: './images/subscription/features/features_image_2.webp',
    },
    {
      title: 'Emergency Alert',
      subTitle: (
        <span>
          Send an SOS with one touch, sharing your location with family instantly. In moments of need,{' '}
          <span className="bold">FamilyTracker</span> is there.
        </span>
      ),
      image: './images/subscription/features/features_image_3.webp',
    },
    {
      title: 'Care, Not Intrusion',
      subTitle: (
        <span>
          Crafted for those who cherish privacy as much as connection, <span className="bold">FamilyTracker</span> is
          your quiet companion.
        </span>
      ),
      image: './images/subscription/features/features_image_4.webp',
    },
    {
      title: 'Location History & Visualized Routes',
      subTitle:
        'Stay connected with your loved ones’ day-to-day journeys. A picture of their day, for peace of mind and conversations.',
      image: './images/subscription/features/features_image_5.webp',
    },
    {
      title: 'Ease of Use',
      subTitle: 'An intuitive interface means staying connected doesn’t have to be complicated.',
      image: './images/subscription/features/features_image_6.webp',
    },
  ];
  return (
    <>
      <Box
        sx={{
          maxWidth: '430px',
          width: '100%',
          background: '#fff',
          paddingBottom: '40px',
          '& img': {
            maxWidth: '100%',
          },
        }}
      >
        <img draggable={false} src={'./images/subscription/map.webp'} alt="map"></img>
        <Typography
          sx={{
            fontFamily: 'Poppins700',
            fontSize: '32px',
            lineHeight: '44px',
            userSelect: 'none',
            textAlign: 'center',
            color: '#051C38',
            margin: '0 20px',
          }}
        >
          The ultimate GPS location tracker for your family
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins400',
            fontSize: '16px',
            lineHeight: '24px',
            userSelect: 'none',
            textAlign: 'center',
            color: '#051C38',
            margin: '20px',
          }}
        >
          <span
            style={{
              fontFamily: 'Poppins700',
            }}
          >
            FamilyTracker
          </span>{' '}
          is more than just an app; it’s a tool for fostering connection, ensuring safety, and enriching the lives of
          families everywhere.
        </Typography>
        <Box
          sx={{
            padding: '0 20px',
          }}
        >
          <QuizButton
            onClick={() => {
              const plans = document.getElementById('get_your_plan');
              if (plans) {
                plans.scrollIntoView({ behavior: 'smooth' });
              }
            }}
            variant={ButtonType.BLUE}
            text="Start Tracking"
          ></QuizButton>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: '390px',
          margin: 'auto',
          padding: '0 20px',
          background: '#E6F3FE',
        }}
      ></Box>
      <Box
        sx={{
          maxWidth: '390px',
          margin: 'auto',
          padding: '0 20px',
          background: '#E6F3FE',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '40px 0',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: 'Poppins700',
                fontSize: '24px',
                lineHeight: 'normal',
                userSelect: 'none',
                color: '#051C38',
                textAlign: 'center',
              }}
            >
              Bringing Loved Ones Closer, Keeping Them Safer
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins400',
                fontSize: '16px',
                lineHeight: '24px',
                userSelect: 'none',
                color: '#051C38',
                textAlign: 'center',
                margin: '15px 0 10px',
                '& .bold': {
                  fontFamily: 'Poppins700',
                },
              }}
            >
              <span className="bold">FamilyTracker</span> has got you covered, providing more calmness and security &
              helping families can feel closer to each other’s daily lives. This is how:
            </Typography>
          </Box>
          {features.map((feature, index) => (
            <Box
              key={feature.title}
              sx={{
                background: index === 2 ? '#051C38' : '#fff',
                borderRadius: '30px',
                padding: '30px',
                '& img': {
                  maxWidth: '100%',
                },
              }}
            >
              <img src={feature.image} alt="feature"></img>
              <Typography
                sx={{
                  fontFamily: 'Poppins700',
                  fontSize: '24px',
                  lineHeight: 'normal',
                  userSelect: 'none',
                  color: index === 2 ? '#E6F3FE' : '#051C38',
                  margin: '20px 0 10px',
                }}
              >
                {feature.title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  userSelect: 'none',
                  color: index === 2 ? '#E6F3FE' : '#051C38',
                  '& .bold': {
                    fontFamily: 'Poppins700',
                  },
                }}
              >
                {feature.subTitle}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography
          id="get_your_plan"
          sx={{
            fontFamily: 'Poppins700',
            fontSize: '32px',
            lineHeight: 'normal',
            userSelect: 'none',
            textAlign: 'center',
            color: '#051C38',
            '& .blue': {
              color: '#1364FF',
            },
          }}
        >
          Choose your plan with <span className="blue">discount</span>
        </Typography>
        <ChoosePlan></ChoosePlan>
      </Box>
      <Box
        sx={{
          background: '#fff',
          maxWidth: '390px',
          padding: '40px 20px 0',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          paddingBottom: '30px',
          '& img': {
            maxWidth: '100%',
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins700',
            fontSize: '24px',
            lineHeight: 'normal',
            userSelect: 'none',
            color: '#051C38',
            textAlign: 'center',
            '& .blue': {
              color: '#1364FF',
            },
          }}
        >
          It takes <span className="blue">5 minutes</span> and <span className="blue">3 steps</span> to always know
          where your loved ones are
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            '& div': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            },
            '& p': {
              fontFamily: 'Poppins400',
              fontSize: '16px',
              lineHeight: '24px',
              userSelect: 'none',
              color: '#051C38',
              textAlign: 'center',

              '& .bold': {
                fontFamily: 'Poppins700',
              },
            },
            '& img': {
              width: '78px',
              height: '70px',
            },
          }}
        >
          <Box>
            <img src={'./images/subscription/features/bullet_1.webp'} alt="step"></img>
            <Typography>
              Download the <span className="bold">FamilyTracker</span> app on your device
            </Typography>
          </Box>
          <Box>
            <img src={'./images/subscription/features/bullet_2.webp'} alt="step"></img>
            <Typography>
              Install the <span className="bold">FamilyTracker</span> app on your family member's or kid's phone or use
              a GPS watch
            </Typography>
          </Box>
          <Box>
            <img src={'./images/subscription/features/bullet_3.webp'} alt="step"></img>
            <Typography>Enjoy peace of mind 24/7</Typography>
          </Box>
        </Box>
      </Box>
      <img
        style={{ width: '100%', maxWidth: '430px' }}
        src={'./images/subscription/features/sitting_mother.webp'}
        alt="sitting mother"
      ></img>
      <Box
        sx={{
          background: '#E6F3FE',
          maxWidth: '390px',
          padding: '40px 20px',
          display: 'flex',
          flexDirection: 'column',
          '& img': {
            maxWidth: '100%',
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins700',
            fontSize: '32px',
            lineHeight: '44px',
            userSelect: 'none',
            color: '#051C38',
            textAlign: 'center',
          }}
        >
          Top-rated location shared App
        </Typography>
        <img
          style={{ width: '230px', height: '40px', margin: '20px auto' }}
          src={'./images/subscription/features/stars.webp'}
          alt="stars"
        ></img>
        <Typography
          sx={{
            fontFamily: 'Poppins400',
            fontSize: '16px',
            lineHeight: '24px',
            userSelect: 'none',
            color: '#051C38',
            textAlign: 'center',
          }}
        >
          Welcome to the future of family connectivity, where every day is an opportunity to stay close, no matter the
          distance.
        </Typography>

        <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column', paddingTop: '30px' }}>
          {reviews.map((review, index) => (
            <Box
              key={index}
              sx={{
                background: '#fff',
                borderRadius: '30px',
                padding: '30px',
                display: 'flex',
                flexDirection: 'column',
                gap: ' 30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    userSelect: 'none',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Poppins500',
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#051C38',
                      userSelect: 'none',
                      marginBottom: '2px',
                    }}
                  >
                    {review.name}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: 'Poppins400',
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: '#81A3C1',
                      userSelect: 'none',
                      marginBottom: '5px',
                    }}
                  >
                    {review.position}
                  </Typography>
                  <img
                    draggable={false}
                    width={92}
                    height={16}
                    src={'./images/subscription/features/stars_small.webp'}
                    alt="review"
                  ></img>
                </Box>
                <img draggable={false} width={80} height={68} src={review.image} alt="review"></img>
              </Box>
              <Box
                sx={{
                  height: '1px',
                  width: '100%',
                  background: 'rgba(129, 163, 193, 0.20)',
                }}
              ></Box>
              <Typography
                sx={{
                  fontFamily: 'Poppins400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  userSelect: 'none',
                  color: '#051C38',
                  '& .bold': {
                    fontFamily: 'Poppins700',
                  },
                }}
              >
                {review.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default MainContent;
