import { Routes } from "../App";


export const redirectToPaymentPage = () => {
  const params = new URLSearchParams(window.location.search);

  const customParams = new URLSearchParams({
    redirectDomain: window.location.origin,
  });

  const redirectUrl = `https://subs.huracanapps.com${Routes.SUBSCRIPTION}?${params}&${customParams}`;
  window.open(redirectUrl, '_self');
};

export const redirectBackAfterPayment = () => {
  const redirectDomain = new URLSearchParams(window.location.search).get('redirectDomain');

  let params = new URLSearchParams(window.location.search);
  params.delete('redirectDomain');

  const redirectUrl = `${redirectDomain}${Routes.DOWNLOAD_APP}?${params}`;
  window.open(redirectUrl, '_self');
};
