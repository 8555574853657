import { APP_CONSTANTS } from '../../utils/constants';

const Terms = (): JSX.Element => (
  <div style={{ padding: '50px' }}>
    <h1> TERMS OF SERVICE</h1>
    <h2>Date: April 12, 2024</h2>
    These Terms of Services (hereinafter referred to as “ToS”) regulate additional issues of using{' '}
    {APP_CONSTANTS.APP_NAME}.<h4>DEFINITIONS AND TERMS</h4>
    In order to understand the rules of the EULA clearly you shall familiarize yourself with the meaning of terms used
    in the EULA. In this EULA the following terms, when capitalized, shall have the following meanings:
    <div style={{ marginLeft: '50px' }}>
      <br /> <b>“Account”</b> – means a personal account of a user of the Platform services, which is provided by the
      Platform Administrator under the Platform Terms of Use or similar document. The Account is accessed with a
      Platform ID (account name) and a password.
      <br /> <b>“App Store”</b> – means a virtual store (platform) managed by Apple for distribution of applications for
      devices running the iOS operating system.
      <br /> <b>“Apple”</b> – means Apple, Inc., a company incorporated in California, the United States of America, any
      parent or group company or any successor thereof that manages the App Store.
      <br /> <b>“Application”, “App”</b> – means an application for a mobile device that We make available on the
      Platform or our Website, as well as any services provided through the Application.
      <br /> <b>“Company”, “We”</b> – means {APP_CONSTANTS.APP_NAME}, registered at the address: {APP_CONSTANTS.ADDRESS}
      <br /> <b>“Content”</b> – means all kinds of content, including, among other, data, texts, images, videos, sounds,
      links, software and other pieces of information in any form, which are made available to You through the App.
      <br /> <b>“Device”</b> – means a smartphone, a tablet or another similar mobile device that You either own or
      otherwise legally control for Your personal non-commercial use.
      <br /> <b>“Google”</b> – means Google Inc, registered in Delaware, USA with the headquarters located at the
      address: 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Google Ireland Limited registered in Ireland
      with the headquarters located at the address: Gordon House, Barrow Street, Dublin 4, Ireland; Google Commerce
      Limited registered in Ireland with the headquarters located at the address: Gordon House, Barrow Street, Dublin 4;
      as well as Google Asia Pacific Pte. Limited registered in Singapore with the headquarters located at the address:
      8 Marina View, Asia Square 1 #30-01, Singapore 018960.
      <br /> <b>“Google Play”</b> – means a virtual store (platform) managed by Google for distribution of applications
      for devices running the Android operating system.
      <br /> <b>“Intellectual Property Rights”</b> – means, collectively, rights under any patent, trademark, copyright
      and trade secret laws, and any other intellectual property or proprietary rights recognized in any country or
      jurisdiction worldwide, including, without limitation, moral or similar rights.
      <br /> <b>“Platform”</b> – Google Play (for users who use the Application on a Device running Android operating
      system) or App Store (for users who use the Application on a Device running iOS operating system).
      <br /> <b>“Platform Administrator”</b> – Google (for Google Play virtual store) and Apple (for App Store virtual
      store).
      <br /> <b>“Subscription”</b> – means a period, during which You use the App as a whole or its certain additional
      Content for a fee.
      <br /> <b>“Website”</b> – means {APP_CONSTANTS.APP_BASE_URL}.
      <br /> <b>“User-generated Content”</b> – means any form of Content, including, among other, data, texts, images,
      videos, sounds, links, that have been posted by users to online platforms, for example, social networks (e.g.
      Instagram, Facebook).
      <br /> <b>“You”</b> – means an individual using the App.
    </div>
    <h4>DEVICE</h4>
    <br />
    If You intend to download onto and/or run the App on any Device not owned by You, You must have the owner’s
    permission to do so. You will be responsible for complying with these ToS whether or not You own the Device.
    <br />
    You are allowed to install and/or use the App only through Your Account.
    <br />
    If You use the Device together with other people, please, make sure that nobody except You can use the Application
    from Your Account. All actions taken in Your Account are considered Your actions in disregard of who actually takes
    such actions. You are fully responsible for all actions taken in Your Account.
    <br />
    If You decide to sell or otherwise dispose of the Device, You shall delete the Application from the Device.
    <h4>INTELLECTUAL PROPERTY</h4>
    <br /> The Company or the licensor of the Company is the only holder of Intellectual Property Rights with regard to
    the Application. The Company retains all rights, title and interest in and to the App and reserves all rights not
    expressly granted to You in these ToS.
    <br /> You may not sell, resell, distribute, redistribute, rent, lease, sublicense, assign, copy, or in any other
    way transfer or grant any rights to the App to any third party, except for the cases specifically set forth by the
    Platform Administrators.
    <br /> You may not distribute or make the App available over any network where it could be downloaded or used by
    multiple users at the same time, except for the cases specifically set forth by the Platform Administrators.
    <br /> You may not use any automatic or manual device or process to interfere with the proper working of the App.
    However, You may use any kind of device or process to delete the App from Your Device.
    <br /> You may not decompile, disassemble, reverse engineer or create derivative works of the App or any parts
    thereof. All modifications or enhancements to the App remain the sole property of the Company.
    <br /> You may not use the App in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with
    these ToS or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as
    viruses, or harmful data, into the App.
    <h4> UPDATES</h4>
    <br /> We may, though not obliged, update the App from time to time, making such updates, supplements or new
    versions of the App available to You. If We release an update (including a new version of the App) We may request
    You to update the App. The update may be downloaded and installed automatically or on the basis of Your consent
    depending on the updates settings of Your Device.
    <br /> The content of any update, supplement or new version of the App is determined exclusively by the Company.
    Under no circumstances do We accept any orders, requires or requests for further development of the App. We reserve
    the right to modify or discontinue any part, or function, or feature of the App at Our own discretion with or
    without a prior notice.
    <br /> If We introduce certain features or functions of the App, We may need to set certain rules for using such
    features or functions. In this case We will introduce changes to the ToS that will take effect on the day when such
    features or functions are released.
    <h4> PURCHASING SUBSCRIPTION. TRIAL PERIOD</h4>
    <br />
    The App as a whole or its certain part (additional Content) is provided on a paid subscription basis. The fee may
    vary depending on the App, the period of the Subscription duration.
    <br />
    Purchase on the Platform.
    <br />
    You may purchase a Subscription on the Platform, in which case it is the Platform Administrator, that collects money
    on our behalf.
    <br />
    The costs of all Subscriptions are available on the web-page of the App on the Platforms.
    <br />
    When You purchase a Subscription, the Platform Administrator charges Your Account and collects money. The payment is
    processed by a payment processing system implemented by the Platform Administrator.
    <br />
    Please, kindly notice that in this case We neither process Your payment nor have any access to Your payment
    information, such as, among other, Your bank card data, Your name or any other related information.
    <br />
    Please, kindly notice that in case of purchase from the Platform, you must independently study the terms and
    conditions for using the Platform, including its terms of refunds.
    <br />
    These ToS don't regulate Your relations with the Platform. Please, check an end user license agreement, terms of
    service, privacy policy or any other related document that regulates the usage of external sites and services.
    <br />
    The digital content is made available to You immediately after the Subscription fee is charged to Your Account.
    <br />
    Direct Purchase from the Company.
    <br />
    You may purchase a Subscription directly from the Company, in which case it is the Company and the external payment
    service providers (Stripe, PayPal etc.), that collects money.
    <br />
    The costs of all Subscriptions along with this ToS as well as Privacy Policy are available for your attention on the
    Website and web-page of the App before the purchase.
    <br />
    When You purchase a Subscription, the payment is processed by a payment processing system you use.
    <br />
    The digital content is made available to You immediately after the trial period begins or after the Subscription fee
    is charged.
    <br />
    Please kindly note that we offer refunds in case of Direct Purchase from the Company (please see clause 12).
    <br />
    Trial periods may be provided depending on the particular Subscription and the App you choose. You may use the App
    or its additional Content free of charge during this period. Please, take into consideration that at the end of the
    trial period it will automatically transform into a paid Subscription in accordance with clause 6 and Your Account
    will be charged for the cost of such Subscription, unless You cancel the Subscription through the subscription
    settings of Your Account at least 24 hours prior to the end of the free trial period.
    <h4>AUTOMATIC RENEWAL OF SUBSCRIPTION. CANCELLATION OF SUBSCRIPTION</h4>
    <br />
    The Subscription will renew automatically for the same period at the end of the Subscription term at the cost of the
    chosen package. Your Account will be charged for the renewed Subscription within 24 hours prior to the end of the
    current Subscription. If You cancel automatic renewal within the last 24 hours of the Subscription, such
    cancellation will take effect at the end of the new Subscription.
    <br />
    You may cancel renewal of the Subscription at least 24 hours before the end of the Subscription: in this case the
    App as a whole or its additional Content will be available to You until the end of the Subscription. After that the
    Subscription will not be renewed automatically.
    <br />
    In order to cancel automatic renewal of the Subscription, please, change subscription settings of Your Account.
    After the end of the Subscription the App as a whole or its additional Content will be available only if a new
    Subscription is purchased.
    <br />
    If You uninstall the App, Your Subscription will not stop automatically. You must cancel automatic renewal of Your
    Subscription in order not to be charged with the cost of the new Subscription.
    <h4> PROFESSIONAL ADVICE</h4>
    <br />
    The Apps are devoted to fitness, yoga, diets and healthy nutrition, as well as meditation and alike. Please, take
    into account that any kind of data provided to You through the App is provided for information purposes only. We
    strongly recommend You to consult Your doctor or nutrition advisor before using any kind of information provided
    through the App. It is up to You whether to use this information as the basis of Your everyday activities and
    decisions. You use this information at Your own risk. Under no circumstances shall We be responsible for any
    consequences of using such information as the basis for Your everyday activities and decisions.
    <h4>LINKS TO EXTERNAL SITES AND SERVICES</h4>
    <br /> The App may contain links to external sites and services (for example, applications for mobile devices). If
    You decide to visit and/or use such external sites and services, You do this at Your own risk. Under no
    circumstances shall We be responsible for the content of such sites and services. We cannot guarantee that these
    sites and services will be available for You or that Your use of such sites and services will be safe.
    <br />
    These ToS don’t regulate Your relations with owners and/or administrators of such external sites and services.
    Please, check an end user license agreement, terms of service, privacy policy or any other related document that
    regulates usage of external sites and services.
    <h4>DISCLAIMER</h4>
    <br />
    To the fullest extent permitted by applicable law, We and Our affiliates, officers, employees, agents, partners, and
    licensors expressly disclaim all warranties of any kind, express or implied, as to the accuracy, adequacy or
    completeness of any of the information contained in Our Apps or created by the services.
    <br />
    The Content in Our Apps is provided to You on an “as is” basis, without any warranties as to merchantability or
    fitness for a particular purpose or use. We do not guarantee that You will attain any anticipated result from the
    use of the Apps.
    <br />
    We make no warranty that:
    <div style={{ marginLeft: '50px' }}>
      <br />
      the App will meet Your requirements;
      <br />
      the App will be error-free, secure, or uninterrupted;
      <br />
      any App functionality errors will be corrected;
      <br />
      the quality of the App will meet Your expectations
    </div>
    <br />
    Any service downloaded or otherwise accessed through the use of Our Apps is at Your own discretion and risk and We
    shall have no responsibility or liability for any errors or omissions. We are not responsible for any damages,
    whether direct or indirect, special or consequential, suffered by You or by any other person from the use of the
    Apps, even if You have been advised of the possibility of such damages. No advice or information, whether oral or
    written, obtained by You from Us or Our Apps shall create any warranty not expressly stated in the ToS. Under no
    circumstances shall Our liability or of any of Our affiliates pursuant to any cause of action, whether in contract,
    tort, or otherwise, exceed the fee paid by You for access to the Apps. Furthermore, We shall have no responsibility
    or liability for delays or failures due to circumstances beyond Our control.
    <h4>TRANSFER OF RIGHTS</h4>
    <br />
    We may transfer Our rights and obligations under these ToS to any other organization or individual without Your
    consent. We will ensure that the transfer will not affect Your rights under the ToS.
    <br />
    You may only transfer Your rights or Your obligations under these ToS to another person if We agree in writing.
    <br />
    YOUR VIOLATION OF THE TOS
    <br />
    We may end Your rights to use the App at any time by contacting You if You have broken the rules of these ToS in a
    serious way. If what You have done can be put right We will give You a reasonable opportunity to do so.
    <br />
    If We end Your rights to use the App:
    <br />
    You must stop all activities authorized by these ToS, including Your use of the App;
    <br />
    You must delete or remove the App from all Devices and immediately destroy all copies of the App which You have and
    confirm to Us that You have done this.
    <h4> REFUND POLICY</h4>
    <br /> Please kindly note that we offer refunds to our customers who made direct purchases from the Company.
    <br /> To request a refund please e-mail a written application to{' '}
    <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a> containing the following information:
    <br /> Name and Surname of the customer, who purchased the services;
    <br /> Payment method (PayPal, Stripe, Debit Card, etc. used to perform a purchase);
    <br /> Date of the payment;
    <br /> Payment receipt or any payment confirmation;
    <br /> Brief justification of the reason for the need for a refund.
    <h4>
      PLEASE BE INFORMED THAT YOUR REFUND REQUEST SHALL CONTAIN ALL THE INFORMATION DESCRIBED BY THE PRESENT CLAUSE 12.2
      A-E IN ORDER TO ALLOW US TO PERFORM A REFUND. THE LACK OF INFORMATION FROM YOUR SIDE IN YOUR REQUEST WILL LEAD TO
      A LACK OF TECHNICAL ABILITY TO MAKE A REFUND PAYMENT. IN CASE YOUR REFUND REQUEST DOES NOT CONTAIN ALL THE
      INFORMATION REQUIRED BY THE PRESENT CLAUSE WE WILL BE UNABLE TO PERFORM A REFUND.
    </h4>
    <h4>MISCELLANEOUS</h4>
    <br /> Each of the paragraphs of these ToS operates separately. If any court or relevant authority decides that any
    of them is unlawful, the remaining paragraphs will remain in full force and effect.
    <br /> Even if We delay enforcing these ToS, We can still enforce them later. If We do not insist immediately that
    You do anything You are required to do under these ToS terms, or if We delay taking steps against You in respect of
    Your breaking the ToS, that will not mean that You do not have to do those things and it will not prevent Us taking
    steps against You at a later date.
    <br /> If You want to learn more about the App or have any problems using it, please, contact Us at{' '}
    <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>
  </div>
);

export default Terms;
