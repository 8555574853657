import { Box } from '@mui/material';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import PaymentModal from './components/PaymentModal';
import { useEffect } from 'react';
import { trackEvent } from '../../api/requests';
import { useQuizState } from '../../providers/QuizProvider/QuizProvider.hooks';
import { usePaymentState } from '../../providers/PaymentProvider/PaymentProvider.hooks';
import { redirectToPaymentPage } from '../../utils/redirectToPayment';

function Subscription() {
  const { userLocation } = useQuizState();
  const { isReadyAppleButton } = usePaymentState();

  useEffect(() => {
    window.scrollTo(0, 0);
    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      fbq('track', 'SubsShown');
    }
    trackEvent('sub screen shown', userLocation);
  }, []);

  useEffect(() => {
    const isOnPaymentDomain = window.location.origin.includes('huracanapps.com');
    if (isReadyAppleButton && !isOnPaymentDomain) {
      redirectToPaymentPage();
    }
  }, [isReadyAppleButton]);

  return (
    <Box
      sx={{
        backgroundColor: '#CFE3F4',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <MainContent></MainContent>
      <Footer></Footer>

      <PaymentModal></PaymentModal>
    </Box>
  );
}

export default Subscription;
