import { LocationConfig } from '../../api/requests';

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}
export const GENDER_ANSWERS: Record<Gender, string> = {
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female',
  [Gender.OTHER]: 'Other',
};

export enum Race {
  White = 'White',
  Indian = 'Indian',
  Pakistani = 'Pakistani',
  Bangladeshi = 'Bangladeshi',
  AfroCaribbean = 'Afro-Caribbean',
  BlackAfrican = 'Black African',
  Chinese = 'Chinese',
  OtherAsian = 'Other Asian',
  OtherEthnicGroup = 'Other ethnic group',
}

export const RACE_ANSWERS: Record<Race, string> = {
  [Race.White]: 'White',
  [Race.Indian]: 'Indian',
  [Race.Pakistani]: 'Pakistani',
  [Race.Bangladeshi]: 'Bangladeshi',
  [Race.AfroCaribbean]: 'Afro-Caribbean',
  [Race.BlackAfrican]: 'Black African',
  [Race.Chinese]: 'Chinese',
  [Race.OtherAsian]: 'Other Asian',
  [Race.OtherEthnicGroup]: 'Other ethnic group',
};
export enum HealthRelatedIssues {
  IHaveChronicConditions = 'IHaveChronicConditions',
  ImReasonablyHealthy = 'ImReasonablyHealthy',
  OccasionalColdsOrLowEnergyLevel = 'OccasionalColdsOrLowEnergyLevel',
  ImPerfectlyHealthy = 'ImPerfectlyHealthy',
  CantTell = 'CantTell',
}

export const HEALTH_RELATED_ISSUES_ANSWERS: Record<HealthRelatedIssues, string> = {
  [HealthRelatedIssues.IHaveChronicConditions]: 'I have chronic conditions',
  [HealthRelatedIssues.ImReasonablyHealthy]: "I'm reasonably healthy",
  [HealthRelatedIssues.OccasionalColdsOrLowEnergyLevel]: 'Occasional colds or low energy level',
  [HealthRelatedIssues.ImPerfectlyHealthy]: "I'm perfectly healthy",
  [HealthRelatedIssues.CantTell]: "Can't tell, I haven't been following",
};

export enum HeartCheckups {
  OnceAYear = 'OnceAYear',
  EveryCoupleOfYears = 'EveryCoupleOfYears',
  OnceIn5Years = 'OnceIn5Years',
  IHaventHadOneYet = 'IHaventHadOneYet',
}

export const HEART_CHECKUPS_ANSWERS: Record<HeartCheckups, string> = {
  [HeartCheckups.OnceAYear]: 'Once a year',
  [HeartCheckups.EveryCoupleOfYears]: 'Every couple of years',
  [HeartCheckups.OnceIn5Years]: 'Once in 5 years',
  [HeartCheckups.IHaventHadOneYet]: "I haven't had one yet",
};

export enum Symptoms {
  HighBloodPressure = 'HighBloodPressure',
  ChestDiscomfortOrPain = 'ChestDiscomfortOrPain',
  HighFatigue = 'HighFatigue',
  ShortnessOfBreath = 'ShortnessOfBreath',
  HighHeartRate = 'HighHeartRate',
  HeadachesDizziness = 'HeadachesDizziness',
  IDontRecallExactly = 'IDontRecallExactly',
  NoneOfTheAbove = 'NoneOfTheAbove',
}

export const SYMPTOMS_ANSWERS: Record<Symptoms, string> = {
  [Symptoms.HighBloodPressure]: 'High blood pressure',
  [Symptoms.ChestDiscomfortOrPain]: 'Chest discomfort or pain',
  [Symptoms.HighFatigue]: 'High fatigue',
  [Symptoms.ShortnessOfBreath]: 'Shortness of breath',
  [Symptoms.HighHeartRate]: 'High heart rate',
  [Symptoms.HeadachesDizziness]: 'Headaches, dizziness',
  [Symptoms.IDontRecallExactly]: "I don't recall exactly",
  [Symptoms.NoneOfTheAbove]: 'None of the above',
};

export enum FamilyHistoryOfHeartIssues {
  YesInMyBloodRelatives = 'YesInMyBloodRelatives',
  HaventHeardOfIt = 'HaventHeardOfIt',
}

export const FAMILY_HISTORY_ANSWERS: Record<FamilyHistoryOfHeartIssues, string> = {
  [FamilyHistoryOfHeartIssues.YesInMyBloodRelatives]: 'Yes, in my blood relatives',
  [FamilyHistoryOfHeartIssues.HaventHeardOfIt]: "Haven't heard of it",
};

export enum TotalCholesterolLevel {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  IDontKnow = 'IDontKnow',
}

export const TOTAL_CHOLESTEROL_LEVEL_ANSWERS: Record<TotalCholesterolLevel, string> = {
  [TotalCholesterolLevel.Low]: 'Low',
  [TotalCholesterolLevel.Normal]: 'Normal',
  [TotalCholesterolLevel.High]: 'High',
  [TotalCholesterolLevel.IDontKnow]: "I don't know",
};

export enum TotalHighDensityLipoprotein {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  IDontKnow = 'IDontKnow',
}

export const TOTAL_HIGH_DENSITY_LIPOPROTEIN_ANSWERS: Record<TotalHighDensityLipoprotein, string> = {
  [TotalHighDensityLipoprotein.Low]: 'Low',
  [TotalHighDensityLipoprotein.Normal]: 'Normal',
  [TotalHighDensityLipoprotein.High]: 'High',
  [TotalHighDensityLipoprotein.IDontKnow]: "I don't know",
};

export enum TotalLowDensityLipoprotein {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  IDontKnow = 'IDontKnow',
}

export const TOTAL_LOW_DENSITY_LIPOPROTEIN_ANSWERS: Record<TotalLowDensityLipoprotein, string> = {
  [TotalLowDensityLipoprotein.Low]: 'Low',
  [TotalLowDensityLipoprotein.Normal]: 'Normal',
  [TotalLowDensityLipoprotein.High]: 'High',
  [TotalLowDensityLipoprotein.IDontKnow]: "I don't know",
};

export enum BloodPressure {
  Below120 = 'Below120',
  Below130 = 'Below130',
  Below140 = 'Below140',
  Over140 = 'Over140',
  ImNotSure = 'ImNotSure',
}

export const BLOOD_PRESSUE_ANSWERS: Record<BloodPressure, string> = {
  [BloodPressure.Below120]: 'Below 120/80',
  [BloodPressure.Below130]: '120-129/80',
  [BloodPressure.Below140]: '130-139/80',
  [BloodPressure.Over140]: 'Over 140/90',
  [BloodPressure.ImNotSure]: "I'm not sure",
};

export enum DealingWithHypertension {
  Yes = 'Yes',
  No = 'No',
  IDontKnow = 'IDontKnow',
  IPreferNotToAnswer = 'IPreferNotToAnswer',
}

export const DEEALING_WITH_HYPERTENSION_ANSWERS: Record<DealingWithHypertension, string> = {
  [DealingWithHypertension.Yes]: 'Yes',
  [DealingWithHypertension.No]: 'No',
  [DealingWithHypertension.IDontKnow]: "I don't know",
  [DealingWithHypertension.IPreferNotToAnswer]: 'I prefer not to answer',
};

export enum MedicationForHighBloodPressure {
  Yes = 'Yes',
  No = 'No',
  IDontKnow = 'IDontKnow',
  IPreferNotToAnswer = 'IPreferNotToAnswer',
}

export const MEDICATION_FOR_HIGH_BLOOD_PRESSURE_ANSWERS: Record<MedicationForHighBloodPressure, string> = {
  [MedicationForHighBloodPressure.Yes]: 'Yes',
  [MedicationForHighBloodPressure.No]: 'No',
  [MedicationForHighBloodPressure.IDontKnow]: "I don't know",
  [MedicationForHighBloodPressure.IPreferNotToAnswer]: 'I prefer not to answer',
};

export enum DiabetesDiagnosedByADoctor {
  Yes = 'Yes',
  No = 'No',
}

export const DIABETES_DIAGNOSED_BY_A_DOCTOR_ANSWERS: Record<DiabetesDiagnosedByADoctor, string> = {
  [DiabetesDiagnosedByADoctor.Yes]: 'Yes',
  [DiabetesDiagnosedByADoctor.No]: 'No',
};

export enum Lifestyle {
  Sedentary = 'Sedentary',
  LowActivity = 'LowActivity',
  ModeratelyActive = 'ModeratelyActive',
  PhysicallyDemanding = 'PhysicallyDemanding',
}

export const LIFESTYLE_ANSWERS: Record<Lifestyle, string> = {
  [Lifestyle.Sedentary]: 'Sedentary',
  [Lifestyle.LowActivity]: 'Low Activity (occasional walks)',
  [Lifestyle.ModeratelyActive]: 'Moderately Active (exercises 2-3 times/week)',
  [Lifestyle.PhysicallyDemanding]: 'Physically demanding (intense training or hard work)',
};

export enum TypicalDay {
  IHaveNoTimeForMyself = 'AHaveNoTimeForMyself',
  ImVeryBusy = 'ImVeryBusy',
  MyScheduleIsPrettyFlexible = 'MyScheduleIsPrettyFlexible',
}

export const TYPICAL_DAY_ANSWERS: Record<TypicalDay, string> = {
  [TypicalDay.IHaveNoTimeForMyself]: 'I have no time for myself',
  [TypicalDay.ImVeryBusy]: "I'm very busy but managing to allocate some me-time",
  [TypicalDay.MyScheduleIsPrettyFlexible]: 'My schedule is pretty flexible',
};

export enum HoursOfSleep {
  LessThan5 = 'LessThan5',
  LessThan6 = 'LessThan6',
  LessThan7 = 'LessThan7',
  MoreThan8 = 'MoreThan8',
}

export const HOURS_OF_SLEEP_ANSWERS: Record<HoursOfSleep, string> = {
  [HoursOfSleep.LessThan5]: 'Less than 5 hours',
  [HoursOfSleep.LessThan6]: '5-6 hours',
  [HoursOfSleep.LessThan7]: '6-7 hours',
  [HoursOfSleep.MoreThan8]: '8+ hours',
};

export enum Smoke {
  Regularly = 'Regularly',
  Occasionally = 'Occasionally',
  IUsed = 'IUsed',
  IDont = 'IDont',
  OnlyE = 'OnlyE',
}

export const SMOKE_ANSWERS: Record<Smoke, string> = {
  [Smoke.Regularly]: 'Yes, regularly',
  [Smoke.Occasionally]: 'Yes, occasionally',
  [Smoke.IUsed]: 'I used to but quit',
  [Smoke.IDont]: "I don't smoke at all",
  [Smoke.OnlyE]: 'Only e-cigarettes or vaping',
};

export enum Alcohol {
  Regularly = 'Regularly',
  Occasionally = 'Occasionally',
  IUsed = 'IUsed',
  IDont = 'IDont',
}

export const ALCOHOL_ANSWERS: Record<Alcohol, string> = {
  [Alcohol.Regularly]: 'Yes, regularly',
  [Alcohol.Occasionally]: 'Yes, occasionally',
  [Alcohol.IUsed]: 'I used to but stopped',
  [Alcohol.IDont]: "I don't drink at all",
};

export enum Drugs {
  ITried = 'ITried',
  Currently = 'Currently',
  IUsed = 'IUsed',
  IDont = 'IDont',
  IPreferNotToDisclose = 'IPreferNotToDisclose',
}

export const DRUGS_ANSWERS: Record<Drugs, string> = {
  [Drugs.ITried]: "I've tried before",
  [Drugs.Currently]: 'Yes, currently',
  [Drugs.IUsed]: 'I used to but quit',
  [Drugs.IDont]: "I don't at all",
  [Drugs.IPreferNotToDisclose]: 'I prefer not to disclose',
};

export enum FeelEnergized {
  Yes = 'Yes',
  No = 'No',
  ItDepends = 'ItDepends',
}

export const FEEL_ENERGIZED_ANSWERS: Record<FeelEnergized, string> = {
  [FeelEnergized.Yes]: 'Yes',
  [FeelEnergized.No]: 'No',
  [FeelEnergized.ItDepends]: 'It depends',
};

export type WeightFormatType = 'lbs' | 'kg';
export type HeightFormatType = 'ft' | 'cm';
export enum AlertLevels {
  LOW = 'Low',
  NORMAL = 'Normal',
  HIGH = 'High',
}

export enum PaymentMethodType {
  APPLE = 'apple',
  CARD = 'card',
}

export type QuizData = {
  gender: Gender | null;
  race: Race | null;
  age: string | undefined;
  weight: string | undefined;
  weightFormat: WeightFormatType;
  height: {
    cm: string | undefined;
    ft: string | undefined;
    in: string | undefined;
  };
  heightFormat: HeightFormatType;
  healthRelatedIssues: HealthRelatedIssues | undefined;
  heartCheckups: HeartCheckups | undefined;
  symptoms: Symptoms[] | undefined;
  familyHistoryOfHeartIssues: FamilyHistoryOfHeartIssues | undefined;
  totalCholesterolLevel: TotalCholesterolLevel | undefined;
  totalHighDensityLipoprotein: TotalHighDensityLipoprotein | undefined;
  totalLowDensityLipoprotein: TotalLowDensityLipoprotein | undefined;
  bloodPressure: BloodPressure | undefined;
  dealingWithHypertension: DealingWithHypertension | undefined;
  medicationForHighBloodPressure: MedicationForHighBloodPressure | undefined;
  diabetesDiagnosedByADoctor: DiabetesDiagnosedByADoctor | undefined;
  lifestyle: Lifestyle | undefined;
  typicalDay: TypicalDay | undefined;
  hoursOfSleep: HoursOfSleep | undefined;
  feelEnergized: FeelEnergized | undefined;
  smoke: Smoke | undefined;
  alcohol: Alcohol | undefined;
  drugs: Drugs | undefined;
  email: string;
  isAgreed: boolean;
  isPaymentModalOpen: boolean;
  selectedPaymentMethod: PaymentMethodType;
};

export const DEFAUL_QUIZ_DATA: QuizData = {
  gender: null,
  race: null,
  age: undefined,
  weight: undefined,
  weightFormat: 'lbs',
  height: {
    cm: undefined,
    ft: undefined,
    in: undefined,
  },
  heightFormat: 'ft',
  healthRelatedIssues: undefined,
  heartCheckups: undefined,
  symptoms: undefined,
  familyHistoryOfHeartIssues: undefined,
  totalCholesterolLevel: undefined,
  totalHighDensityLipoprotein: undefined,
  totalLowDensityLipoprotein: undefined,
  bloodPressure: undefined,
  dealingWithHypertension: undefined,
  medicationForHighBloodPressure: undefined,
  diabetesDiagnosedByADoctor: undefined,
  lifestyle: undefined,
  typicalDay: undefined,
  hoursOfSleep: undefined,
  feelEnergized: undefined,
  smoke: undefined,
  alcohol: undefined,
  drugs: undefined,
  email: '',
  isAgreed: true,
  isPaymentModalOpen: false,
  selectedPaymentMethod: PaymentMethodType.CARD,
};

export type QuizContextType = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  quizData: QuizData;
  setQuizData: React.Dispatch<React.SetStateAction<QuizData>>;
  userLocation: LocationConfig | null;
};
