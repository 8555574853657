import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import QuizButton, { ButtonType } from '../common/QuizButton';
import { Routes } from '../../App';
import { trackEvent } from '../../api/requests';
import { useQuizState } from '../../providers/QuizProvider/QuizProvider.hooks';
import { usePaymentState } from '../../providers/PaymentProvider/PaymentProvider.hooks';
import { redirectToPaymentPage } from '../../utils/redirectToPayment';

function Main() {
  const navigate = useNavigate();
  const { userLocation } = useQuizState();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { isReadyAppleButton } = usePaymentState();

  useEffect(() => {
    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      fbq('track', 'IntroShown');
    }
    trackEvent('intro screen shown', userLocation);
  }, []);

  const handleClick = () => {
    if (isReadyAppleButton) {
      redirectToPaymentPage();
    } else {
      navigate(`${Routes.SUBSCRIPTION}${window.location.search}`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#CFE3F4',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '460px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#1364FF',
            backgroundImage: 'url(./images/main/pattern_background.webp)',
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              padding: '0px 20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins700',
                fontSize: '32px',
                color: '#fff',
                textAlign: 'center',
                userSelect: 'none',
                marginTop: '40px',
              }}
            >
              Bringing Loved Ones Closer, Keeping Them Safer
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins400',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#E6F3FE',
                textAlign: 'center',
                userSelect: 'none',
                marginTop: '20px',
                marginBottom: '40px',
                '& .bold': {
                  fontFamily: 'Poppins700',
                },
              }}
            >
              With innovative features designed for the modern family, <span className="bold">FamilyTracker</span>{' '}
              ensures that safety and connectivity go hand in hand.
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins700',
                fontSize: '24px',
                color: '#fff',
                textAlign: 'center',
                userSelect: 'none',
                marginBottom: '15px',
              }}
            >
              I would like to track:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                justifyContent: 'center',
                '& .wrapper': {
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',

                  '& img': {
                    width: '158px',
                    height: '181px',
                  },
                  '& div': {
                    borderRadius: '24px',
                    width: '147px',
                    height: '48px',
                    background: '#458FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Poppins600',
                    fontSize: '16px',
                    color: '#FFF',
                    lineHeight: 'normal',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      opacity: isMobile ? 1 : 0.8,
                    },
                  },
                },
              }}
            >
              <Box className="wrapper" onClick={handleClick}>
                <img src={'./images/main/quiz_kid.webp'} alt="My Kids"></img>
                <Box>My Kids</Box>
              </Box>
              <Box className="wrapper" onClick={handleClick}>
                <img src={'./images/main/quiz_partner.webp'} alt="Partner"></img>
                <Box>Partner</Box>
              </Box>
              <Box className="wrapper" onClick={handleClick}>
                <img src={'./images/main/quiz_family.webp'} alt="Family"></img>
                <Box>Family</Box>
              </Box>
            </Box>
          </Box>
          <img
            style={{
              maxWidth: '100%',
            }}
            src={'./images/main/subtract.webp'}
            alt="decor"
          ></img>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            padding: '0px 20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins700',
              fontSize: '32px',
              color: '#051C38',
              lineHeight: '44px',
              textAlign: 'center',
              userSelect: 'none',
              marginTop: '40px',
            }}
          >
            The ultimate GPS location tracker for your family
          </Typography>
          <img width={264} height={264} src={'./images/main/map_circle.webp'} alt="map"></img>
          <Typography
            sx={{
              fontFamily: 'Poppins400',
              fontSize: '16px',
              color: '#051C38',
              lineHeight: '24px',
              textAlign: 'center',
              userSelect: 'none',
              '& .bold': {
                fontFamily: 'Poppins700',
              },
            }}
          >
            <span className="bold">FamilyTracker</span> is more than just an app; it’s a tool for fostering connection,
            ensuring safety, and enriching the lives of families everywhere.
          </Typography>
          <QuizButton onClick={handleClick} variant={ButtonType.BLUE} text="Start Tracking"></QuizButton>
        </Box>

        <Box
          sx={{
            height: '1px',
            width: '100%',
            background: 'rgba(129, 163, 193, 0.20)',
            margin: '30px 0 15px',
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: '20px',
            '& .link': {
              fontFamily: 'Poppins400',
              fontSize: '12px',
              color: '#051C38',
              textDecoration: 'none',
              transition: 'all 0.3s ease',
              whiteSpace: 'nowrap',
              '&:hover': {
                opacity: 0.8,
              },
            },
          }}
        >
          <Link target="_blank" href={Routes.PRIVACY} className="link">
            Privacy Policy
          </Link>
          <Link target="_blank" href={Routes.TERMS} className="link">
            Terms of Use
          </Link>
          <Link target="_blank" href={Routes.COOKIE_POLICY} className="link">
            Cookie Policy
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Main;
