import React from 'react';
import { QuizProvider } from './providers/QuizProvider/QuizProvider';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';

import Main from './components/Main';
// import Quiz from './components/Quiz';
import Subscription from './components/Subscription';
import Terms from './components/Docs/Terms';
import CookiePolicy from './components/Docs/CookiePolicy';
import Privacy from './components/Docs/Privacy';
import DownloadApp from './components/DownloadApp';
import { PaymentProvider } from './providers/PaymentProvider/PaymentProvider';

export enum Routes {
  MAIN = '/tracker/',
  // QUIZ = '/tracker/quiz',
  SUBSCRIPTION = '/tracker/subscription',
  PRIVACY = '/tracker/privacy',
  TERMS = '/tracker/terms',
  COOKIE_POLICY = '/tracker/cookie-policy',
  DOWNLOAD_APP = '/tracker/congrats',
}

const router = createBrowserRouter([
  {
    path: '/tracker/*',
    element: <Main></Main>,
  },
  // {
  //   path: Routes.QUIZ,
  //   element: <Quiz></Quiz>,
  // },
  {
    path: Routes.SUBSCRIPTION,
    element: <Subscription></Subscription>,
  },
  {
    path: Routes.COOKIE_POLICY,
    element: <CookiePolicy></CookiePolicy>,
  },
  {
    path: Routes.PRIVACY,
    element: <Privacy></Privacy>,
  },
  {
    path: Routes.TERMS,
    element: <Terms></Terms>,
  },
  {
    path: Routes.DOWNLOAD_APP,
    element: <DownloadApp></DownloadApp>,
  },
]);

function App() {
  console.log('0.000031');
  return (
    <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY || ''}>
      <Elements>
        <QuizProvider>
          <PaymentProvider>
            <RouterProvider router={router} />
          </PaymentProvider>
        </QuizProvider>
      </Elements>
    </RecurlyProvider>
  );
}

export default App;
