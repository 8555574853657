import { useState, ReactNode, createContext, FC, useEffect, useRef, FormEvent } from 'react';

import { PaymentContextType, PaymentPlanType } from './PaymentProvider.types';
import { useRecurly } from '@recurly/react-recurly';

import { ApplePayError, ApplePayInstance } from '@recurly/recurly-js';
import { createSubscriptionNoUser, getPaymentConfig } from '../../api/requests';
import { redirectBackAfterPayment } from '../../utils/redirectToPayment';
import { Routes } from '../../App';
import { useQuizState } from '../QuizProvider/QuizProvider.hooks';
import { PaymentMethodType } from '../QuizProvider/QuizProvider.types';

export const PaymentContext = createContext<PaymentContextType | null>(null);

export const PaymentProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { setQuizData, quizData, userLocation } = useQuizState();
  const [isReadyAppleButton, setReadyAppleButton] = useState(false);
  const [applePay, setApplePay] = useState<ApplePayInstance | null>(null);
  const recurly = useRecurly();
  const formRef = useRef<HTMLFormElement>(null);
  const [paymentFormError, setPaymentFormError] = useState<string | null>(null);
  const [applePayError, setApplePayError] = useState<ApplePayError | null>(null);
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlanType[] | null>(null);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<PaymentPlanType | null>(null);
  const [subscriptionUserEmail, setSubscriptionUserEmail] = useState<string | null>(null);

  useEffect(() => {
    getPaymentConfig()
      .then((response) => {
        setPaymentPlans(response.plans);
        setSelectedPaymentPlan(response.plans[0]);
      })
      .catch((e) => {
        console.error('getPaymentConfig error', e);
      });
  }, []);

  useEffect(() => {
    if (selectedPaymentPlan) {
      setApplePay(
        recurly.ApplePay({
          country: 'US',
          currency: 'USD',
          label: selectedPaymentPlan.name_full ?? '',
          total: String(selectedPaymentPlan?.trial_sum ?? selectedPaymentPlan?.sum),
        })
      );
    }
  }, [selectedPaymentPlan]);

  useEffect(() => {
    applePay?.ready(() => {
      setReadyAppleButton(true);
      setQuizData((prev) => ({
        ...prev,
        selectedPaymentMethod: PaymentMethodType.APPLE,
      }));
    });
  }, [applePay]);

  useEffect(() => {
    if (applePay && subscriptionUserEmail !== null) {
      applePay.on('error', function (err) {
        console.error('Apple Pay error', err);
        setApplePayError(err);
        setPaymentLoading(false);
      });

      applePay.on('token', function (token) {
        console.log('apple pay token', token);

        if (!token) {
          console.error('token is not defined');
        }

        createSubscriptionNoUser({
          email: subscriptionUserEmail,
          plan_id: selectedPaymentPlan?.id,
          token_id: token.id,
          location: userLocation,
        })
          .then((response) => {
            console.log('createSubscriptionNoUser response', response);
            if ('error' in response) {
              console.log('response.error', response.error);
              setApplePayError({ message: response.error } as ApplePayError);
              setPaymentLoading(false);
            }
            if ('id' in response) {
              // @ts-ignore
              if (window.fbq) {
                // @ts-ignore
                fbq('track', 'Purchase', {value: selectedPaymentPlan?.trial_sum, currency: 'USD', code: selectedPaymentPlan?.id});
              }

              const hasRedirectDomain = new URLSearchParams(window.location.search).get('redirectDomain');

              if (hasRedirectDomain) {
                redirectBackAfterPayment();
              } else {
                window.open(Routes.DOWNLOAD_APP, '_self');
              }
            }
          })
          .catch((e) => {
            console.error('error', e);
          })
          .finally(() => {
            setPaymentLoading(false);
          });
      });
    }
  }, [applePay, subscriptionUserEmail]);

  function handlePayByApple() {
    if (applePay) {
      setSubscriptionUserEmail(quizData.email);

      applePay.begin(() => {
        console.log('applePay.begin');
        setPaymentLoading(true);
      });
    }
  }

  function handleSubmitForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (formRef.current) {
      setPaymentLoading(true);
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          console.log('err', err);
          setPaymentFormError(err.message);
          setPaymentLoading(false);
        } else {
          console.log('token', token);

          createSubscriptionNoUser({
            email: quizData.email,
            plan_id: selectedPaymentPlan?.id,
            token_id: token.id,
            location: userLocation,
          })
            .then((response) => {
              console.log('createSubscriptionNoUser  response', response);
              if ('error' in response) {
                console.log('response.error', response.error);
                setPaymentFormError(response.error);
                setPaymentLoading(false);
              }
              if ('id' in response) {
                // @ts-ignore
                if (window.fbq) {
                  // @ts-ignore
                  fbq('track', 'Purchase', {value: selectedPaymentPlan?.trial_sum, currency: 'USD', code: selectedPaymentPlan?.id});
                }

                const hasRedirectDomain = new URLSearchParams(window.location.search).get('redirectDomain');

                if (hasRedirectDomain) {
                  redirectBackAfterPayment();
                } else {
                  window.open(Routes.DOWNLOAD_APP, '_self');
                }
              }
            })
            .catch((e) => {
              console.error('error', e);
            })
            .finally(() => {
              setPaymentLoading(false);
            });
        }
      });
    }
  }

  const value: PaymentContextType = {
    isReadyAppleButton,
    handlePayByApple,
    handleSubmitForm,
    paymentFormError,
    applePayError,
    formRef,
    paymentPlans,
    selectedPaymentPlan,
    setSelectedPaymentPlan,
    isPaymentLoading,
  };

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
};
