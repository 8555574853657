import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { Routes } from '../../../App';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#051C38',

        maxWidth: '390px',
        margin: 'auto',
        padding: '40px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img draggable={false} width={84} height={84} src={'./images/subscription/money_back.svg'} alt="money_back"></img>
      <Typography
        sx={{
          fontFamily: 'Poppins700',
          fontSize: '24px',
          lineHeight: 'normal',
          textAlign: 'center',
          color: '#E6F3FE',
          userSelect: 'none',
          marginTop: '30px',
          marginBottom: '15px',
        }}
      >
        Money Back guarantee
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins400',
          fontSize: '16px',
          lineHeight: 'normal',
          textAlign: 'center',
          userSelect: 'none',
          color: '#E6F3FE',
          marginBottom: '30px',
        }}
      >
        With our trial, you`ll gain a comprehensive view of your cardiac health and the means to influence it - in no
        time positively! We are ready to return your money if you feel uncomfortable using the App. Find more about
        applicable limitations in our
      </Typography>
      <QuizButton
        onClick={() => {
          const plans = document.getElementById('get_your_plan');
          if (plans) {
            plans.scrollIntoView({ behavior: 'smooth' });
          }
        }}
        variant={ButtonType.BLUE}
        text="Start Tracking"
      ></QuizButton>
      <Typography
        sx={{
          fontFamily: 'Poppins400',
          fontSize: '12px',
          lineHeight: 'normal',
          textAlign: 'center',
          color: '#E6F3FE',
          userSelect: 'none',
          marginTop: '30px',
        }}
      >
        All rights reserved. 2024
      </Typography>
      <Box sx={{ height: '1px', margin: '15px 0', background: 'rgba(129, 163, 193, 0.20)', width: '100%' }}></Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          '& .link': {
            userSelect: 'none',
            fontFamily: 'Poppins400',
            fontSize: '12px',
            color: '#E6F3FE',
            textDecoration: 'none',
            transition: 'all 0.3s ease',
            whiteSpace: 'nowrap',
            '&:hover': {
              opacity: 0.8,
            },
          },
        }}
      >
        <Link target="_blank" href={Routes.PRIVACY} className="link">
          Privacy Policy
        </Link>
        <Link target="_blank" href={Routes.TERMS} className="link">
          Terms of use
        </Link>
        <Link target="_blank" href={Routes.COOKIE_POLICY} className="link">
          Cookie Policy
        </Link>
      </Box>
    </Box>
  );
}

export default Footer;
